if (process.client) {
  window.dataLayer = window.dataLayer || []
  // Fix: unecessary many session per user on Google Analytics
  // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })
}

function sanitize(value, removeDashes) {
  if (!value) return undefined
  const val = String(value)
  let sanitized = val

  try {
    sanitized = val.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
  } catch (error) {}

  if (removeDashes) {
    sanitized = sanitized.replace(/-/g, '')
  }

  sanitized = sanitized.trim()
  return sanitized || undefined
}

function getUserData(userData) {
  if (!userData) return {}

  return {
    id: sanitize(userData.id),
    internal_id: sanitize(userData.internal_id),
    email: sanitize(userData.email),
    phone_number: userData.phone_number || '',
    ip: userData.user_ip || '',
    // eslint-disable-next-line camelcase
    gender: sanitize(userData.gender || userData.ship_address?.guessed_gender),
    first_name: sanitize(userData.first_name),
    last_name: sanitize(userData.last_name),
    street: sanitize(userData.ship_address?.address1),
    city: sanitize(userData.ship_address?.city),
    // eslint-disable-next-line camelcase
    region: sanitize(userData.ship_address?.state_name),
    postal_code: sanitize(userData.ship_address?.zipcode, true),
    country: sanitize(userData.country_name),
    country_code: sanitize(userData.country_id),
  }
}

export default {
  methods: {
    pushDataLayer({
      event,
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      ga4EventValue,
      insiderEventValue,
      ecommerce,
    }) {
      if (!process.client) {
        process.env.NODE_ENV === 'development' &&
          // eslint-disable-next-line no-console
          console.warn(
            'Trying to push to dataLayer on server-side. Please use this only on client side.'
          )
        return
      }
      const store = this.$store?.$storeCode || eventValue?.store

      const user = this.$store?.state?.user

      window.dataLayer.push({
        event,
        eventCategory,
        eventAction,
        eventLabel,
        ecommerce,
        eventValue,
        eventStore: store,
        ga4EventValue,
        insiderEventValue,
        userEventValue: user,
        user_data: getUserData(user),
      })
    },
  },
}
